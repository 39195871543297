import { downloadCSV } from 'react-admin';
import { get } from 'lodash';
import jsonExport from 'jsonexport/dist';

const OrderExporter = (order) => {
  let setData = {};
  const orderList = [];
  order.map((item) => {
    const orderNumber = get(item, 'orderNumber', '');
    const userFullName = get(item, 'userFullName', '');
    const email = get(item, 'email', '');
    const chefStatus = get(item, 'chefStatus', '');
    const customerStatus = get(item, 'customerStatus', '');
    const createdAt = get(item, 'createdAt', '');
    const eventDate = get(item, 'eventDate', '');
    const noOfAttendees = get(item, 'noOfAttendees', '');
    const assignedChef = get(item, 'assignedChef', '');
    const budget = get(item, 'budget', '');
    const eventTime = get(item, 'eventTime', '');
    setData = {
      orderNumber: orderNumber,
      userFullName: userFullName,
      email: email,
      chefStatus: chefStatus,
      customerStatus: customerStatus,
      createdAt: createdAt,
      eventDate: eventDate,
      noOfAttendees: noOfAttendees,
      assignedChef: assignedChef,
      budget: budget,
      eventTime: eventTime,
    };
    orderList.push(setData);
    return orderList;
  });
  jsonExport(
    orderList,
    {
      headers: [
        'orderNumber',
        'userFullName',
        'email',
        'chefStatus',
        'customerStatus',
        'createdAt',
        'eventDate',
        'noOfAttendees',
        'assignedChef',
        'budget',
        'eventTime',
      ],
      rename: [
        'Order number',
        'Personal information',
        'Email',
        'Chef Status',
        'Customer Status',
        'Requested',
        'Event day',
        'No of Attendees',
        'Chef',
        'Budget',
        'Time',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'order');
    }
  );
};
export default OrderExporter;
