import React from 'react';
import { useRecordContext, TextField } from 'react-admin';

function ExpandColumn({ source }) {
  const record = useRecordContext();
  const description = record[source] || '';
  return (
    <div className={`truncate-text`}>
      <span>{description}</span>
    </div>
  );
}

export default ExpandColumn;
