import React from 'react';
import { PropTypes } from 'prop-types';
import { Modal, Typography, Button, IconButton, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import '../../styles/modal.css';

const CustomModal = (props) => {
  const {
    open,
    title,
    body,
    submitButtonTxt,
    cancelButtonTxt,
    actionButtonStatus,
    onAction,
    onClose,
    error,
  } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <div className="modalContainer">
        <div className="modalHeader">
          <Typography>{title}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <div className="modalBody">
          <Typography>{body}</Typography>
        </div>
        <div className="modalFooter">
          <div style={{ width: '100%' }}>
            {error !== '' && (
              <Alert className="errorTxt" severity="error">
                {error}
              </Alert>
            )}
          </div>
          <div className="buttonSection">
            <Button onClick={onClose} className="cancelButton">
              {cancelButtonTxt}
            </Button>
            <Button
              variant="contained"
              disabled={!actionButtonStatus}
              className="activateButton"
              onClick={() => onAction()}
            >
              {submitButtonTxt}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  actionButtonStatus: PropTypes.bool.isRequired,
  submitButtonTxt: PropTypes.string.isRequired,
  cancelButtonTxt: PropTypes.string.isRequired,
  onAction: PropTypes.func,
  onClose: PropTypes.func,
};
export default CustomModal;
