import { get, has, isEmpty } from 'lodash';
import {
  serving,
  dietary,
  spiceLevel,
  allChefStatus,
  allCustomerStatus,
} from '../data/option';
import moment from 'moment';

const findById = (ids, type) => {
  if (type === 'spiceLevel') {
    const nameToIdMap = spiceLevel.reduce((acc, item) => {
      acc[item.name] = item.id;
      return acc;
    }, {});

    return nameToIdMap[ids] || null;
  } else {
    const data = type === 'serving' ? serving : dietary;
    return ids.map((id) => {
      const foundItem = data.find((item) => item.id === id);
      return foundItem ? foundItem.name : null;
    });
  }
};

const findByName = (ids, type) => {
  let data = type === 'chefStatus' ? allChefStatus : allCustomerStatus;
  const selectedItem = data.find((item) => item.id === ids);
  return selectedItem ? selectedItem.name : '';
};

const modifiedResponse = (response, type, resource, params) => {
  let newResp = [];
  let data = {};
  if (type === 'GET_LIST' && resource === 'chef') {
    if (
      has(response, ['json', 'data', 'docs']) &&
      !isEmpty(response.json.data.docs)
    ) {
      let name = '';
      get(response, 'json.data.docs', []).map((chef) => {
        if (has(chef, 'firstname') && has(chef, 'surname')) {
          name = `${get(chef, 'firstname', '')} ${get(chef, 'surname', '')}`;
        }
        let cuisineTypes;
        if (has(chef, 'cuisineType') && has(chef, 'primaryCuisine')) {
          const mergedCuisines = [...chef.primaryCuisine];
          for (const element of chef.cuisineType) {
            mergedCuisines.push(element);
          }
          cuisineTypes = mergedCuisines.join(',');
        }

        data = {
          _id: chef?._id || '',
          id: chef?.chefId.toString().padStart(4, '0') || '',
          status: chef?.status === 'inActive' ? 'Inactive' : 'Active' || '',
          displayName: chef?.displayName || '',
          email: chef?.email || '',
          phoneNumber: chef?.phoneNumber || '',
          profileCompleted: get(chef, 'profileCompleted', ''),
          city: chef?.address?.city || '',
          address: chef?.address?.address || '',
          city: chef?.address?.city || '',
          cuisineType: cuisineTypes || '',
          minCater: chef?.cater?.minPeople || '',
          maxCater: chef?.cater?.maxPeople || '',
          name: name || '',
          availabilityDay: chef?.availabilityDay || '',
          sortCode: chef?.account?.sortCode || '',
          accountNumber: chef?.account?.number || '',
        };
        newResp.push(data);
      });
      return newResp;
    }
  } else if (type === 'GET_LIST' && resource === 'menus') {
    if (has(response, ['json', 'data', 'docs'])) {
      get(response, 'json.data.docs', []).map((menu, index) => {
        const dishNames = menu.dishesIncluded.map((item) => item.name);
        let chefName = '';
        if (has(menu.chef, 'firstname') && has(menu.chef, 'surname')) {
          chefName = `${menu.chef.firstname} ${get(menu.chef, 'surname', '')}`;
        } else {
          chefName = menu.chef.displayName;
        }
        let menuName = '';
        if (has(menu, 'name')) {
          menuName = menu.name;
        } else {
          menuName = menu.type;
        }
        data = {
          id: menu.id,
          chefId: get(menu, 'chef._id', ''),
          chefName: chefName || '',
          menuName: menuName.charAt(0).toUpperCase() + menuName.slice(1),
          dietary: findById(menu.dietary, 'dietary'),
          servingStyle: findById(menu.servingStyle, 'serving'),
          dishes: dishNames,
        };
        newResp.push(data);
      });
      return newResp;
    }
  } else if (type === 'GET_LIST' && resource === 'dishes') {
    if (has(response, ['json', 'data', 'docs'])) {
      get(response, 'json.data.docs', []).map((dish) => {
        let chefName = '';
        if (has(dish.chef, 'firstname') && has(dish.chef, 'surname')) {
          chefName = `${dish.chef.firstname} ${get(dish.chef, 'surname', '')}`;
        } else {
          chefName = dish.chef.displayName;
        }
        data = {
          id: dish.id,
          chefId: get(dish, 'chef._id', ''),
          chefName: get(dish, 'chef.displayName', ''),
          dishName: dish.name,
          unitServes: dish.perUnitServe.type,
          amount: dish.perUnitServe.amount,
          price: dish.perUnitServe?.price ? `£${dish.perUnitServe.price}` : '',
          spiceLevel: findById(dish.spiceLevel, 'spiceLevel'),
          dietary: findById(dish.dietary, 'dietary'),
          serving: findById(dish.servingStyle, 'serving'),
          discription: dish.description,
        };
        newResp.push(data);
      });
      return newResp;
    }
  } else if (type === 'GET_LIST' && resource === 'orders') {
    if (
      has(response, ['json', 'data', 'docs']) &&
      !isEmpty(response.json.data.docs)
    ) {
      get(response, 'json.data.docs', []).map((order) => {
        let eventTime = '';
        if (
          has(order, ['eventDetails', 'time']) &&
          !isEmpty(order.eventDetails.time)
        ) {
          eventTime = order.eventDetails.time
            .split('-')
            .map((part) => part.split(':').slice(0, 2).join(':'))
            .join('-');
        }
        const getPriceOrCreateText = (order) => {
          const price = get(order, 'invoice.invoiceItems[0].price', null);
          const priceNumber = parseFloat(price);
          return !isNaN(priceNumber) ? `£${priceNumber.toFixed(2)}` : 'Create';
        };
        let eventDate;
        if (moment(order.eventDetails.date, moment.ISO_8601, true).isValid()) {
          eventDate = moment(order.eventDetails.date).format('DD/MM/YYYY');
        } else {
          eventDate = order.eventDetails.date;
        }
        data = {
          id: order.orderId,
          mongoOrderId: get(order, '_id', ''),
          orderNumber: parseInt(order.orderId),
          personalInformation: '',
          userFullName: `${get(order, 'user.firstname', '')} ${get(
            order,
            'user.surname',
            ''
          )}`,
          userFirstName: get(order, 'user.firstname', ''),
          userSurname: get(order, 'user.surname', ''),
          userPhoneNumber: get(order, 'user.phoneNumber', ''),
          email: get(order, 'user.email', ''),
          chefStatus:
            findByName(order.chefStatus, 'chefStatus') || 'Chef Pending',
          assignedChef:
            (order.currentAssignedChef &&
              order.currentAssignedChef.length > 0 &&
              order.currentAssignedChef
                .map((item) =>
                  item.surname
                    ? `${item.firstname} ${get(item, 'surname', '')}`
                    : item.firstname
                )
                .join(', ')) ||
            'Select',
          assignedChefData: order.currentAssignedChef,
          customerStatus:
            findByName(order.customerStatus, 'customerStatus') ||
            'Awaiting Proposal',
          createdAt: moment(order.createdAt).format('DD/MM/YYYY'),
          eventDate: eventDate,
          eventTime: eventTime,
          chefStatusValue: get(order, 'chefStatus', 'chefPending'),
          customerStatusValue: get(order, 'customerStatus', 'awaitingProposal'),
          address: get(order, 'enquirySummary.eventAddress', ''),
          chefName: order.selectedChef,
          noOfAttendees: order.enquirySummary.attendees,
          companyName: order.companyName,
          chefAtEvent: !isEmpty(order.enquirySummary.chefEvent) ? 'Yes' : 'No',
          budget: `${!isEmpty(order.enquirySummary.budget) ? '£' : ''}${
            order.enquirySummary.budget
          }`,
          invoice: getPriceOrCreateText(order),
          status: get(order, 'invoice.status', ''),
          longStatus: get(order, 'invoice.longStatus', ''),
          invoiceMongoId: get(order, 'invoice._id', ''),
        };
        newResp.push(data);
      });
      return newResp;
    }
  }
};

export default modifiedResponse;
