import React from 'react';
import { IconContext } from 'react-icons';
import { FaPencil } from 'react-icons/fa6';

const PencilIcon = () => (
  <IconContext.Provider value={{ size: '1.5em' }}>
    <div>
      <FaPencil />
    </div>
  </IconContext.Provider>
);

export default PencilIcon;
