// MyIcon.js
import React from 'react';
import { IconContext } from 'react-icons';
import { ImSpoonKnife } from 'react-icons/im';

const DishIcon = () => (
  <IconContext.Provider value={{ size: '1.5em' }}>
    <div>
      <ImSpoonKnife />
    </div>
  </IconContext.Provider>
);

export default DishIcon;
