// MyIcon.js
import React from 'react';
import { IconContext } from 'react-icons';
import { MdRoomService } from "react-icons/md";

const DishIcon = () => (
  <IconContext.Provider value={{ size: '1.5em' }}>
    <div>
      <MdRoomService />
    </div>
  </IconContext.Provider>
);

export default DishIcon;
