import React, { useState, useEffect } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import '../../styles/pdfmodal.css';
import CloseIcon from '@mui/icons-material/Close';

// Set workerSrc for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  p: 4,
  maxHeight: '80vh',
  overflowY: 'auto',
};

const PdfViewerModal = ({ isOpen, onClose, base64 }) => {
  const [pdfData, setPdfData] = useState('');

  useEffect(() => {
    if (base64) {
      // Convert base64 to binary string and create a blob URL
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);

      setPdfData(blobUrl);

      // Clean up URL object when component unmounts
      return () => {
        URL.revokeObjectURL(blobUrl);
      };
    }
  }, [base64]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="pdf-viewer-modal"
      aria-describedby="pdf-viewer-modal-description"
    >
      <Box sx={style}>
        <div className="modalHeader">
          <h2 id="pdf-viewer-modal">Invoice Details</h2>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          {/* <button onClick={onClose}>Close</button> */}
        </div>
        <div className="modalBody">
          <Document file={pdfData}>
            <Page pageNumber={1} />
          </Document>
        </div>
      </Box>
    </Modal>
  );
};

export default PdfViewerModal;
