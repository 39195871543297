const styles = ({
  main: {
    backgroundImage: `url("${process.env.REACT_APP_S3_BUCKET_URL}/brand/background-green.png")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%'
  },
  avatar: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 80
  },
  icon: { display: 'none' }
});

export default styles;
