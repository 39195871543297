// src/App.js
import React, { useState } from 'react';
import { Admin, Resource } from 'react-admin';
import restHapiProvider from './dataProviders/index';
import ChefsList from './Pages/chefs/ChefsList';
import customTheme from './customTheme';
import OrderList from './Pages/orders/OrdersList'
import MenusList from './Pages/menus/MenusList';
import DishesList from './Pages/dishes/DishesList';
import AuthProvider from './Pages/Login/Provider/AuthProvider';
import LoginPage from './Pages/Login/component/LoginPage';

import ChefIcon from './icons/ChefIcon';
import MenuIcon from './icons/MenuIcon';
import DishIcon from './icons/DishIcon';
import OrderIcon from './icons/OrderIcon';

import './styles/globals.css';

const App = () => (
  <Admin
    dataProvider={restHapiProvider(process.env.REACT_APP_API_BASE_URL)}
    theme={customTheme}
    loginPage={LoginPage}
    authProvider={AuthProvider}
  >
    <Resource name="orders" list={OrderList} icon={OrderIcon} />
    <Resource name="chef" list={ChefsList} icon={ChefIcon} />
    <Resource name="menus" list={MenusList} icon={MenuIcon} />
    <Resource name="dishes" list={DishesList} icon={DishIcon} />
  </Admin>
);

export default App;

//Anurag Sharma: You may remove this comment, it's for test.
//
//
//
//
//
//
//
