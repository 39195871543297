/* eslint-disable indent */
const { get, has } = require('lodash');

const setAPIUrl = (apiUrl, type, resource) => {
  if (resource === 'chef' && type === 'GET_LIST') {
    apiUrl = process.env.REACT_APP_API_BASE_URL;
  } else if (resource === 'chef' && type === 'CREATE') {
    apiUrl = process.env.REACT_APP_API_BASE_URL;
  } else if (resource === 'chef' && type === 'GET_ONE') {
    apiUrl = process.env.REACT_APP_API_BASE_URL;
  } else if (resource === 'menus' && type === 'GET_LIST') {
    apiUrl = process.env.REACT_APP_API_BASE_URL;
  } else if (resource === 'menus' && type === 'CREATE') {
    apiUrl = process.env.REACT_APP_API_BASE_URL;
  } else if (resource === 'menus' && type === 'GET_ONE') {
    apiUrl = process.env.REACT_APP_API_BASE_URL;
  } else if (resource === 'dishes' && type === 'GET_LIST') {
    apiUrl = process.env.REACT_APP_API_BASE_URL;
  } else if (resource === 'dishes' && type === 'CREATE') {
    apiUrl = process.env.REACT_APP_API_BASE_URL;
  } else if (resource === 'dishes' && type === 'GET_ONE') {
    apiUrl = process.env.REACT_APP_API_BASE_URL;
  } else {
    apiUrl = process.env.REACT_APP_API_BASE_URL;
  }

  return apiUrl;
};

const setResource = (type, resource) => {
  if (resource === 'chef' && (type === 'GET_LIST' || type === 'GET_ONE')) {
    resource = 'v1/chef';
  } else if (resource === 'chef' && (type === 'UPDATE' || type === 'GET_ONE')) {
    resource = 'v1/chef';
  } else if (
    resource === 'menus' &&
    (type === 'GET_LIST' || type === 'GET_ONE')
  ) {
    resource = 'v1/menus';
  } else if (
    resource === 'dishes' &&
    (type === 'GET_LIST' || type === 'GET_ONE')
  ) {
    resource = 'v1/dishes';
  } else if (
    resource === 'orders' &&
    (type === 'GET_LIST' || type === 'GET_ONE')
  ) {
    resource = 'v1/order';
  }
  return resource;
};

const setParams = (type, resource, params) => {
  if (type === 'GET_LIST' && resource === 'v1/chef') {
    const field = get(params, 'sort.field', '');
    if (
      params.sort === null ||
      params.sort === undefined ||
      field === 'chefId' ||
      field === 'id'
    ) {
      params.sort = {
        field: 'chefId',
        chefID: 'DESC',
      };
    }
    params.filter = { ...params.filter };
    params.filter.$populate = '';

    if (params.filter && params.filter.address && params.filter.address.city) {
      const city = params.filter.address.city;
      delete params.filter.address.city;
      params.filter.$isearch = `address.city|${city}`;
    }
    if (params.filter && params.filter.address) {
      delete params.filter.address;
    }
  }

  if (type === 'GET_LIST' && resource === 'v1/menus') {
    const field = get(params, 'sort.field', '');
    if (
      params.sort === null ||
      params.sort === undefined ||
      field === 'createdAt'
    ) {
      params.sort = {
        field: 'createdAt',
        chefId: 'DESC',
      };
    }
    if (!params.filter) {
      params.filter = {};
    }
    params.filter.$populate = 'chef,dishesIncluded';
    if (params.filter && params.filter.dietary) {
      params.filter['dietary[$in]'] = params.filter.dietary.join(',');
      delete params.filter.dietary;
    }
    if (params.filter && params.filter.servingStyle) {
      params.filter['servingStyle[$in]'] = params.filter.servingStyle.join(',');
      delete params.filter.servingStyle;
    }
  }

  if (type === 'GET_LIST' && resource === 'v1/dishes') {
    const field = get(params, 'sort.field', '');
    if (
      params.sort === null ||
      params.sort === undefined ||
      field === 'createdAt'
    ) {
      params.sort = {
        field: 'createdAt',
        chefId: 'DESC',
      };
    }
    if (!params.filter) {
      params.filter = {};
    }
    params.filter.$populate = 'chef';
  }

  if (type === 'GET_LIST' && resource === 'v1/user/list') {
    if (has(params, ['filter', 'profile'])) {
      params.filter.profile.type = 'chefs';
    } else {
      params.filter.profile = {
        type: 'chef',
      };
    }
  }
  if (type === 'GET_LIST' && resource === 'v1/order') {
    const field = get(params, 'sort.field', '');
    if (
      params.sort === null ||
      params.sort === undefined ||
      field === 'createdAt'
    ) {
      params.sort = {
        field: 'orderId',
        orderId: 'DESC',
      };
    }
    if (!params.filter) {
      params.filter = {};
    }
    params.filter.$populate = 'selectedChef,user,invoice';
    // params.filter.$populate = 'chef';
  }

  if (type === 'GET_ONE' && resource === 'v1/user/list') {
    const makerList = JSON.parse(localStorage.getItem('makerlist'));
    const makerPhoneNumber = makerList.filter((item) => {
      return item.id === params.id;
    });
    const phone = makerPhoneNumber[0].phoneNumber.replace(/\+/g, '');
    params.phoneNumber = phone;
  }

  return params;
};

module.exports = {
  setResource,
  setParams,
  setAPIUrl,
};
