// MyIcon.js
import React from 'react';
import { IconContext } from 'react-icons';
import { CgProfile } from 'react-icons/cg';

const ChefIcon = () => (
  <IconContext.Provider value={{ size: '1.5em' }}>
    <div>
      <CgProfile />
    </div>
  </IconContext.Provider>
);

export default ChefIcon;
