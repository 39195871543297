import React from 'react';
import { Login, LoginForm } from 'react-admin';
// import { withStyles } from '@material-ui/core/styles';

import styles from '../Styles/LoginPage';

// const CustomLoginForm = withStyles({
//   button: { background: '#45C6EF' }
// })(LoginForm);

const CustomLoginPage = (props) => <Login {...props} />;

export default CustomLoginPage;
