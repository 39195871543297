// src/PostList.js
import { React, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Button,
  useRecordContext,
} from 'react-admin';
import { get, isEmpty } from 'lodash';
import DishExporter from './DishExporter';
import PencilIcon from '../../icons/PencilIcon';
import ExpandColumn from '../components/ExpandColumn';

const postFilters = [<TextInput label="Chef Name" source="chefName" />];

const CustomButton = ({ onClick }) => {
  const record = useRecordContext();

  const handleClick = () => {
    if (record) {
      onClick(record);
    } else {
      console.error('Record data is undefined or null.');
    }
  };

  return (
    <Button color="secondary" onClick={handleClick}>
      <PencilIcon />
    </Button>
  );
};

const DishesList = (props) => {
  const handleButtonClick = async (record) => {
    if (record && !isEmpty(record.chefId)) {
      const baseUrl = `${process.env.REACT_APP_WEB_APP_URL}/impersonation`;
      const idToken = await localStorage.getItem('token');
      const queryParams = new URLSearchParams({
        page: 'dishes',
        chefId: record.chefId,
        token: idToken,
      });
      const url = `${baseUrl}?${queryParams}`;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      }
    } else {
      console.error('Record data is undefined or null.');
    }
  };

  return (
    <List {...props} exporter={DishExporter} filters={postFilters}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="chefName" label="Chef Name" sortable={false} />
        <CustomButton onClick={handleButtonClick} label="Action" />
        <TextField source="dishName" label="Dish Name" sortable={false} />
        <TextField source="unitServes" label="Unit/Serves" sortable={false} />
        <TextField source="amount" label="Amount" sortable={false} />
        <TextField source="price" label="Price" sortable={false} />
        <TextField source="spiceLevel" label="Spice level" sortable={false} />
        <TextField source="dietary" label="Diatary" sortable={false} />
        <TextField source="serving" label="Serving" sortable={false} />
        <ExpandColumn
          source="discription"
          label="Description"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default DishesList;
