import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getToken = () => {
  return localStorage.getItem('token');
};

const apiWrapper = async (endpoint, method = 'GET', data = null) => {
  let response;
  try {
    const token = getToken();
    if (token) {
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    switch (method.toUpperCase()) {
      case 'GET':
        response = await apiClient.get(endpoint);
        break;
      case 'POST':
        response = await apiClient.post(endpoint, data);
        break;
      case 'PATCH':
        response = await apiClient.patch(endpoint, data);
        break;
      case 'DELETE':
        response = await apiClient.delete(endpoint);
        break;
      default:
        throw new Error(`Unsupported method: ${method}`);
    }
    return response.data;
  } catch (error) {
    response = { statusCode: 500, data: {} };
    return response;
  }
};

export default apiWrapper;
