import React from 'react';
import { useRecordContext } from 'react-admin';
import { Box, Card, Divider, CardContent, Grid, Typography } from '@mui/material';
import { get } from 'lodash';
import { isEmpty } from 'lodash';



const OrderDetails = () => {
    const record = useRecordContext();
    let order = {};
    if (!isEmpty(record)) {
        order = {
            user: [
                { label: 'Name', value: get(record, 'userFirstName', '')},
                { label: 'Surname', value: get(record, 'userSurname', '')},
                { label: 'Phone number', value:  get(record, 'userPhoneNumber', '')},
                { label: 'Email', value: get(record, 'email', '')}
            ],
            order: [
                { label: 'Location', value: get(record, 'address', '')},
                { label: 'Budget', value: get(record, 'budget', '')},
                { label: 'Date', value: get(record, 'eventDate', '')},
                { label: 'Time', value: get(record, 'eventTime', '')},
                { label: 'Address', value: get(record, 'address', '')},
                { label: 'Company Name', value: get(record, 'companyName', '')},
            ]
        }
    }
    if (!record) return null;
    return (
        <Card sx={{ width: 600, margin: 'auto' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" class="title" gutterBottom align="center">
                            Order Number {record.id}
                        </Typography>
                    </Grid>
                </Grid>
                <Box height={20}>&nbsp;</Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom align="left">
                            User
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <table>
                            {order.user.map(user => <tr><th>{user.label}</th><td>{user.value}</td></tr>)}
                        </table>                            
                        <Box height={20}>&nbsp;</Box>
                        <Typography variant="h6" gutterBottom align="left">
                            Order
                        </Typography>
                        <Divider />
                        <table>
                            {order.order.map(order => <tr><th>{order.label}</th><td>{order.value}</td></tr>)}
                        </table>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default OrderDetails;
