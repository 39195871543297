const serving = [
  { name: 'Individual boxes', id: 'individual-boxes' },
  { name: 'Sharing platters', id: 'sharing-platters' },
  { name: 'Finger food and canapes', id: 'finger-food-and-canapes' },
  { name: 'Buffet (with services)', id: 'buffet-with-services' },
  { name: 'Fine dinning', id: 'fine-dinning' },
];

const dietary = [
  { id: 'vegetarians', name: 'Vegetarians' },
  { id: 'pescatarian', name: 'Pescatarian' },
  { id: 'dairy-free', name: 'Dairy free' },
  { id: 'gluten-free', name: 'Gluten free' },
  { id: 'vegan', name: 'Vegan' },
  { id: 'halal', name: 'Halal' },
  { id: 'kosher', name: 'Kosher' },
  { id: 'not-applicable', name: 'Not applicable' },
];

const spiceLevel = [
  { id: 'Spicy', name: 'spicy' },
  { id: 'Mild', name: 'mild' },
  { id: 'Non spicy', name: 'non-spicy' },
];

const chefStatus = [
  { value: 'confirmed', name: 'Confirmed' },
  { value: 'cancelled', name: 'Cancelled' },
  { value: 'delivered', name: 'Delivered' },
];

const customerStatus = [
  { value: 'awaitingProposal', name: 'Awaiting Proposal' },
  { value: 'awaitingConfirmation', name: 'Awaiting confirmation' },
  { value: 'confirmed', name: 'Confirmed' },
  { value: 'cancelled', name: 'Cancelled' },
  { value: 'delivered', name: 'Delivered' },
];

const allChefStatus = [
  { id: 'chefPending', name: 'Chef Pending' },
  { id: 'chefAsigned', name: 'Chef Assigned' },
  { id: 'confirmed', name: 'Confirmed' },
  { id: 'cancelled', name: 'Cancelled' },
  { id: 'delivered', name: 'Delivered' },
];

const allCustomerStatus = [
  { id: 'awaitingProposal', name: 'Awaiting Proposal' },
  { id: 'awaitingConfirmation', name: 'Awaiting confirmation' },
  { id: 'confirmed', name: 'Confirmed' },
  { id: 'cancelled', name: 'Cancelled' },
  { id: 'delivered', name: 'Delivered' },
];

const invoiceStatus = [
  { id: 'Draft', name: 'Draft' },
  { id: 'Open', name: 'Open' },
  { id: 'Paid', name: 'Paid' },
  { id: 'Overdue', name: 'Overdue' },
];

module.exports = {
  dietary,
  serving,
  spiceLevel,
  chefStatus,
  customerStatus,
  allChefStatus,
  allCustomerStatus,
  invoiceStatus,
};
