import { downloadCSV } from 'react-admin';
import { get } from 'lodash';
import jsonExport from 'jsonexport/dist';
const DishExporter = (dish) => {
  let setData = {};
  const dishesList = [];
  dish.map((item) => {
    const chefName = get(item, 'chefName', '');
    const dishName = get(item, 'dishName', '');
    const unitServes = get(item, 'unitServes', '');
    const amount = get(item, 'amount', '');
    const price = get(item, 'price', '');
    const spiceLevel = get(item, 'spiceLevel', '');
    const dietary = get(item, 'dietary', '').join(', ');
    const serving = get(item, 'serving', '').join(', ');
    const discription = get(item, 'discription', '');
    setData = {
      chefName: chefName,
      dishName: dishName,
      unitServes: unitServes,
      amount: amount,
      price: price,
      spiceLevel: spiceLevel,
      dietary: dietary,
      serving: serving,
      discription: discription,
    };
    dishesList.push(setData);
    return dishesList;
  });
  jsonExport(
    dishesList,
    {
      headers: [
        'chefName',
        'dishName',
        'unitServes',
        'amount',
        'price',
        'spiceLevel',
        'dietary',
        'serving',
        'discription',
      ],
      rename: [
        'Chef Name',
        'Dish Name',
        'Unit/Serves',
        'Amount',
        'Price',
        'Spice Level',
        'Dietary',
        'Serving',
        'Discription',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'dishes');
    }
  );
};
export default DishExporter;
