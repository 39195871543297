import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import axios from 'axios';
import { get } from 'lodash';

const AuthProvider = async (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const url = `${process.env.REACT_APP_API_BASE_URL}/v1/auth/loginPassword`;
    return new Promise((resolve, reject) => {
      axios
        .post(
          url,
          {
            email: username,
            password: password,
          },
          {
            headers: {
              'content-type': 'application/json',
            },
          }
        )
        .then((response) => {
          const statusCode = get(response, 'data.statusCode');
          if (statusCode !== 200) {
            if (statusCode === 401) {
              reject(new Error('You are not authorised user.'));
            }
            reject(new Error('User Name or Password is incorrect'));
          }
          const idToken = get(response, 'data.data.access_token', '');
          localStorage.setItem('token', idToken);
          localStorage.setItem('username', username);
          resolve();
        })
        .catch((error) => {
          if (error !== '') {
            reject(new Error(error.message));
          }
          reject(new Error('User Name or Password is incorrect'));
        });
    });
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      return Promise.reject(new Error('Error while authenticating user'));
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    const userName = await localStorage.getItem('username');
    return userName
      ? Promise.resolve()
      : Promise.reject(new Error('Check UserName'));
  }
  return Promise.reject(new Error('Unknown Method'));
};

export default AuthProvider;
