// MyIcon.js
import React from 'react';
import { IconContext } from 'react-icons';
import { BiFoodMenu } from 'react-icons/bi';

const MenuIcon = () => (
  <IconContext.Provider value={{ size: '1.5em' }}>
    <div>
      <BiFoodMenu />
    </div>
  </IconContext.Provider>
);

export default MenuIcon;
