import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import apiWrapper from '../../api/wrapper';
import Select from 'react-select';
import { get } from 'lodash';

const SelectChefModal = ({ rowData, handleAssignedChef }) => {
  const [selectedChefs, setSelectedChefs] = useState([]);
  const [allChefs, setAllChefs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await apiWrapper(
        `/v1/chef?status=active&$limit=9999`,
        'GET'
      );
      const chefsData = resp.data.docs.map((chef) => ({
        value: chef._id,
        label: `${get(chef, 'firstname', '')} ${get(chef, 'surname', '')}`,
      }));

      const sortedData = chefsData.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
      setAllChefs(sortedData);

      // Set already selected chefs from assignedChefData
      if (rowData.assignedChefData && rowData.assignedChefData.length > 0) {
        const selectedValues = rowData.assignedChefData.map(
          (chef) => chef.chefId
        );
        setSelectedChefs(selectedValues);
      }
    };
    fetchData();
  }, []);

  const handleCheckboxChange = (event, chefId) => {
    const updatedSelection = event.target.checked
      ? [...selectedChefs, chefId]
      : selectedChefs.filter((id) => id !== chefId);
    setSelectedChefs(updatedSelection);
    handleAssignedChef(updatedSelection);
  };

  const handleSelectChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedChefs(selectedValues);
    handleAssignedChef(selectedValues);
  };

  return (
    <div>
      <div className="modal-checkbox">
        {rowData.chefName.map((chef, index) => (
          <div key={chef._id}>
            <Checkbox
              checked={selectedChefs.includes(chef._id)}
              onChange={(event) => handleCheckboxChange(event, chef._id)}
            />
            <span>{`${index + 1}. ${chef.firstname} ${chef.surname}`}</span>
          </div>
        ))}
      </div>
      <Select
        isMulti
        value={allChefs.filter((chef) => selectedChefs.includes(chef.value))}
        options={allChefs}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default SelectChefModal;
