import { downloadCSV } from 'react-admin';
import { get } from 'lodash';
import jsonExport from 'jsonexport/dist';
const MenuExporter = (menu) => {
  let setData = {};
  const menusList = [];
  menu.map((item) => {
    const chefName = get(item, 'chefName', '');
    const menuName = get(item, 'menuName', '');
    const dietary = get(item, 'dietary', []).join(', ');
    const serving = get(item, 'serving', []).join(', ');
    const dishes = get(item, 'dishes', []).join(', ');
    setData = {
      chefName: chefName,
      menuName: menuName,
      dietary: dietary,
      serving: serving,
      dishes: dishes,
    };
    menusList.push(setData);
    return menusList;
  });
  jsonExport(
    menusList,
    {
      headers: ['chefName', 'menuName', 'dietary', 'serving', 'dishes'],
      rename: ['Chef Name', 'Menu Name', 'Dietary', 'Serving', 'Dishes'],
    },
    (err, csv) => {
      downloadCSV(csv, 'menus');
    }
  );
};
export default MenuExporter;
