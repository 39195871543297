import { downloadCSV } from 'react-admin';
import { get } from 'lodash';
import jsonExport from 'jsonexport/dist';
const ChefExporter = (chef) => {
  let setData = {};
  const chefList = [];
  chef.map((item) => {
    const chefId = get(item, 'id', '');
    const status = get(item, 'status', '');
    const displayName = get(item, 'displayName', '');
    const email = get(item, 'email', '');
    const phoneNumber = get(item, 'phoneNumber', '');
    const city = get(item, 'city', '');
    const address = get(item, 'address', '');
    const cuisineType = get(item, 'cuisineType', '');
    const minCater = get(item, 'minCater', '');
    const maxCater = get(item, 'maxCater', '');
    const name = get(item, 'name', '');
    const availabilityDay = get(item, 'availabilityDay', '');
    const sortCode = get(item, 'sortCode', '');
    const accountNumber = get(item, 'accountNumber', '');
    setData = {
      status: status,
      displayName: displayName,
      email: email,
      phoneNumber: phoneNumber,
      city: city,
      address: address,
      cuisineType: cuisineType,
      minCater: minCater,
      maxCater: maxCater,
      name: name,
      availabilityDay: availabilityDay,
      sortCode: sortCode,
      accountNumber: accountNumber,
      chefId: chefId,
    };
    chefList.push(setData);
    return chefList;
  });
  jsonExport(
    chefList,
    {
      headers: [
        'chefId',
        'status',
        'displayName',
        'email',
        'phoneNumber',
        'city',
        'address',
        'cuisineType',
        'minCater',
        'maxCater',
        'availabilityDay',
        'name',
        'sortCode',
        'accountNumber',
      ],
      rename: [
        'ID',
        'Status',
        'Display name',
        'Email',
        'Phone',
        'City',
        'Full address',
        'Cuisine',
        'Min Cater',
        'Max Cater',
        'Availability',
        'Name On Card',
        'Sort Code',
        'Account Number',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'chef');
    }
  );
};
export default ChefExporter;
