// src/PostList.js
import React, { useState } from 'react';
import {
  List,
  DatagridConfigurable,
  TextField,
  ChipField,
  useRecordContext,
  useRefresh,
  SelectInput,
} from 'react-admin';
import OrderDetail from './OrderDetail';
import { makeStyles } from '@material-ui/core/styles';
import CustomModal from '../modal/CustomModal';
import SelectChefModal from './SelectChefModal';
import { Select, MenuItem } from '@mui/material';
import { chefStatus, customerStatus } from '../../data/option.js';
import apiWrapper from '../../api/wrapper';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { has, get } from 'lodash';
import InputLabel from '@mui/material/InputLabel';
import OrderExporter from './OrderExporter';
import {
  allChefStatus,
  allCustomerStatus,
  invoiceStatus,
} from '../../data/option.js';
import PdfViewerModal from './PdfViewerModal';

const useStyles = makeStyles({
  chip: {
    cursor: 'pointer',
  },
  text: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

const postFilters = [
  <SelectInput
    label="Chef Status"
    source="chefStatus"
    choices={allChefStatus}
  />,
  <SelectInput
    label="Customer Status"
    source="customerStatus"
    choices={allCustomerStatus}
  />,
  <SelectInput
    label="Invoice Status"
    source="invoiceStatus"
    choices={invoiceStatus}
  />,
];

const OrderList = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedChefs, setSelectedChefs] = useState([]);
  const [base64Pdf, setBase64Pdf] = useState(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = React.useState(false);
  const [selectedRowForInvoiceModal, setSelectedRowInvoiceModal] =
    React.useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [modalType, setModalType] = useState('');
  const [ctaStatus, setCTAStatus] = useState(true);
  const [error, setError] = useState('');
  const record = useRecordContext();

  const classes = useStyles();
  const refresh = useRefresh();

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleStatusChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedOption(selectedOption);
    // onSelect(selectedOption);
  };

  const asigneChef = async () => {
    setError('');
    setCTAStatus(false);
    const resp = await apiWrapper(
      `/v1/order/assigned/${get(selectedRowData, 'mongoOrderId', '')}`,
      'PATCH',
      { currentAssignedChef: selectedChefs }
    );
    setCTAStatus(true);
    if (has(resp, 'statusCode') && resp.statusCode === 200) {
      await setSelectedOption('chefAsigned');
      updateStatus('chefStatus', 'chefAsigned');
      setIsModalOpen(false);
      refresh();
    } else {
      setError('An error occurred. Please contact the developer team.');
    }
  };

  const CustomInvoiceField = (props) => {
    const record = useRecordContext();

    const handleNewModalClick = async (rowData) => {
      if (rowData.invoice === 'Create') {
        setSelectedRowInvoiceModal(rowData);
        setIsInvoiceModalOpen(true);
      } else {
        const resp = await apiWrapper(
          `/v1/invoice/pdf/${get(rowData, 'invoiceMongoId', '')}`,
          'get'
        );
        if (has(resp, 'statusCode') && resp.statusCode === 200) {
          setBase64Pdf(resp.data.pdf);
          setIsPdfModalOpen(true);
          refresh();
        } else {
          setError('An error occurred. Please contact the developer team.');
        }
      }
    };

    return (
      <span
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={(event) => {
          event.stopPropagation();
          handleNewModalClick(record);
        }}
      >
        {record ? record[props.source] : ''}
      </span>
    );
  };

  const CustomTextField = ({ source, label, conditions }) => {
    const classes = useStyles();
    const record = useRecordContext();

    const handleClick = () => {
      // Handle click action if needed
    };

    const getTextColor = (record) => {
      if (has(record, 'status') && record.status === 'Paid') {
        return '#219653';
      } else if (has(record, 'status') && record.status === 'Open') {
        return '#EB5757';
      } else if (has(record, 'status') && record.status === 'Overdue') {
        return '#BFBF00';
      } else {
        return '#606060';
      }
      return 'inherit';
    };

    return (
      <TextField
        source={source}
        record={record}
        label={label}
        className={classes.root}
        style={{
          color: getTextColor(record),
          // fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '130%',
        }}
        onClick={handleClick}
      />
    );
  };

  const handleAssignedChef = (selected) => {
    setSelectedChefs(selected);
  };

  const updateStatus = async (type, chefAsigned = '') => {
    setError('');
    setCTAStatus(false);
    const resp = await apiWrapper(
      `/v1/order/${get(selectedRowData, 'mongoOrderId', '')}`,
      'PATCH',
      {
        [type === 'chefStatus' ? 'chefStatus' : 'customerStatus']:
          chefAsigned !== '' ? 'chefAsigned' : selectedOption,
      }
    );
    setCTAStatus(true);
    if (has(resp, 'statusCode') && resp.statusCode === 200) {
      setIsModalOpen(false);
      refresh();
    } else {
      setError('An error occurred. Please contact the developer team.');
    }
  };

  const CustomChip = ({ source, label }) => {
    const record = useRecordContext();
    const openModal = () => {
      const status =
        source === 'chefStatus'
          ? record.chefStatusValue
          : record.customerStatusValue;
      setSelectedOption(status);
      setModalType(source);
      setError('');
      // setCTAStatus(false);
      setIsModalOpen(true);
      setSelectedRowData(record);
    };

    if (!record) {
      return null;
    }
    const getStatusBackgroundColor = (statusValue, type) => {
      const colorMap = {
        chefStatusValue: {
          chefPending: '#EB5757',
          chefAsigned: '#F78B44',
          confirmed: '#219653',
          cancelled: 'white',
          delivered: '#312C28',
        },
        customerStatusValue: {
          awaitingProposal: '#EB5757',
          awaitingConfirmation: '#F78B44',
          confirmed: '#219653',
          cancelled: 'white',
          delivered: '#312C28',
        },
      };

      return colorMap[type][statusValue] || 'inherit';
    };
    const getStatusTextColor = (statusValue) => {
      if (statusValue === 'cancelled') {
        return 'black';
      } else {
        return 'white';
      }
    };
    const getStatusBorderColor = (statusValue) => {
      if (statusValue === 'cancelled') {
        return 'black';
      } else {
        return 'white';
      }
    };
    return record.assignedChef !== 'Select' && source === 'assignedChef' ? (
      <Box display="flex" alignItems="center" onClick={openModal}>
        <TextField
          fullWidth
          variant="standard"
          className={classes.text}
          record={record}
          source={source}
          label={label}
          sortable={false}
        />
        <ArrowForwardIosIcon />
      </Box>
    ) : (
      <Box
        className={
          !(source === 'chefStatus'
            ? record.chefStatusValue === 'chefPending' ||
              record.chefStatusValue === 'delivered'
            : record.customerStatus === 'delivered') && classes.chip
        }
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => {
          if (
            !(source === 'chefStatus'
              ? record.chefStatusValue === 'chefPending' ||
                record.chefStatusValue === 'delivered'
              : record.customerStatus === 'delivered')
          ) {
            openModal();
          }
        }}
      >
        {source === 'chefStatus' ? (
          <ChipField
            record={record}
            source={source}
            label={label}
            sortable={false}
            className={classes.chip}
            style={{
              backgroundColor: getStatusBackgroundColor(
                record.chefStatusValue,
                'chefStatusValue'
              ),
              color: getStatusTextColor(
                record.chefStatusValue,
                'chefStatusValue'
              ),
              border: `1px solid ${getStatusBorderColor(
                record.chefStatusValue,
                'chefStatusValue'
              )}`,
            }}
          />
        ) : source === 'customerStatus' ? (
          <ChipField
            record={record}
            source={source}
            label={label}
            sortable={false}
            className={classes.chip}
            style={{
              backgroundColor: getStatusBackgroundColor(
                record.customerStatusValue,
                'customerStatusValue'
              ),
              color: getStatusTextColor(
                record.customerStatusValue,
                'chefStatusValue'
              ),
              border: `1px solid ${getStatusBorderColor(
                record.customerStatusValue,
                'chefStatusValue'
              )}`,
            }}
          />
        ) : (
          <ChipField
            record={record}
            source={source}
            label={label}
            sortable={false}
            className={classes.chip}
            style={{
              backgroundColor: '#EB5757',
            }}
          />
        )}
        {!(source === 'chefStatus'
          ? record.chefStatusValue === 'chefPending' ||
            record.chefStatusValue === 'delivered'
          : record.customerStatus === 'delivered') && <KeyboardArrowDownIcon />}
      </Box>
    );
  };

  const submitNewModalData = async () => {
    setError('');
    setCTAStatus(false);
    const resp = await apiWrapper(`/v1/freeagent/invoice`, 'POST', {
      email: selectedRowForInvoiceModal.email,
      firstname: selectedRowForInvoiceModal.userFirstName,
      surname: selectedRowForInvoiceModal.userSurname,
      phoneNumber: selectedRowForInvoiceModal.userPhoneNumber,
      orderId: get(selectedRowForInvoiceModal, 'mongoOrderId', ''),
      priceValue: selectedRowForInvoiceModal.field1,
      orderNumber: selectedRowForInvoiceModal.id.toString(),
    });
    setCTAStatus(true);
    if (has(resp, 'statusCode') && resp.statusCode === 200) {
      setIsInvoiceModalOpen(false);
      refresh();
    } else {
      setError('An error occurred. Please contact the developer team.');
    }
  };

  return (
    <>
      <List {...props} exporter={OrderExporter} filters={postFilters}>
        <DatagridConfigurable
          // rowClick="expand"
          expand={<OrderDetail />}
          expandSingle
          bulkActionButtons={false}
        >
          <TextField source="id" label="Order number" />
          <TextField
            source="userFullName"
            label="Personal information"
            sortable={false}
          />
          <TextField source="email" label="Email" sortable={false} />
          <CustomChip source={'chefStatus'} label="Chef Status" />
          <CustomChip source={'customerStatus'} label="Customer Status" />
          <TextField source="createdAt" label="Requested" sortable={false} />
          <TextField source="eventDate" label="Event day" sortable={false} />
          <TextField
            source="noOfAttendees"
            label="No of Attendees"
            sortable={false}
            textAlign="right"
          />
          <CustomChip source={'assignedChef'} label="Chef" />
          <TextField
            source="budget"
            label="Budget"
            sortable={false}
            textAlign="right"
          />
          <TextField
            source="eventTime"
            label="Time"
            sortable={false}
            className="col-xs"
            sx={{ width: '80px' }}
          />
          <CustomInvoiceField source="invoice" label="Invoice" />
          {/* <TextField
            source="invoice"
            label="Invoice"
            sortable={false}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={(event, record) => {
              event.stopPropagation();
              handleNewModalClick(record);
            }}
          /> */}
          <CustomTextField source="longStatus" label="Status" conditions={[]} />
        </DatagridConfigurable>
      </List>
      {isModalOpen && modalType === 'assignedChef' && (
        <CustomModal
          open={isModalOpen}
          title="Select chef"
          body={
            <SelectChefModal
              rowData={selectedRowData}
              handleAssignedChef={handleAssignedChef}
            />
          }
          submitButtonTxt={'Save Changes'}
          cancelButtonTxt="Cancel"
          // onAction={asigneChef}
          actionButtonStatus={ctaStatus}
          onClose={handleCloseModal}
          onAction={asigneChef}
          // chefData={selectedChef}
          error={error}
        />
      )}
      {isModalOpen && modalType === 'chefStatus' && (
        <CustomModal
          open={isModalOpen}
          title="Update chef status"
          body={
            <div>
              <InputLabel id="chef-status-label">Chef status</InputLabel>
              <Select
                fullWidth
                labelId="chef-status-label"
                onChange={handleStatusChange}
                value={selectedOption}
              >
                {selectedOption === '' && (
                  <MenuItem disabled>Select an option</MenuItem>
                )}
                {chefStatus.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          }
          submitButtonTxt={'Update status'}
          cancelButtonTxt="Cancel"
          actionButtonStatus={ctaStatus}
          onClose={handleCloseModal}
          onAction={() => updateStatus('chefStatus')}
          error={''}
        />
      )}

      {isInvoiceModalOpen && (
        <CustomModal
          open={isInvoiceModalOpen}
          title="Create Invoice"
          body={
            <form>
              <div className="form-group">
                {/* <label className="form-label">Price</label> */}
                <input
                  type="number"
                  placeholder="Enter a price"
                  value={selectedRowForInvoiceModal?.field1 || ''}
                  onChange={(event) =>
                    setSelectedRowInvoiceModal((prevState) => ({
                      ...prevState,
                      field1: event.target.value,
                    }))
                  }
                  className="form-input"
                />
              </div>
            </form>
          }
          submitButtonTxt={'Submit'}
          cancelButtonTxt="Cancel"
          actionButtonStatus={ctaStatus}
          onClose={() => setIsInvoiceModalOpen(false)}
          onAction={() => submitNewModalData()}
          error={error}
        />
      )}

      {isPdfModalOpen && base64Pdf && (
        <PdfViewerModal
          isOpen={isPdfModalOpen}
          onClose={() => setIsPdfModalOpen(false)}
          base64={base64Pdf}
        />
      )}

      {isModalOpen && modalType === 'customerStatus' && (
        <CustomModal
          open={isModalOpen}
          title="Update customer status"
          body={
            <div>
              <InputLabel id="customer-status-label">
                Customer status
              </InputLabel>
              <Select
                fullWidth
                labelId="customer-status-label"
                onChange={handleStatusChange}
                value={selectedOption}
              >
                {selectedOption === '' && (
                  <MenuItem disabled>Select an option</MenuItem>
                )}
                {customerStatus.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          }
          submitButtonTxt={'Update status'}
          cancelButtonTxt="Cancel"
          actionButtonStatus={ctaStatus}
          onClose={handleCloseModal}
          onAction={() => updateStatus('customerStatus')}
          error={error}
        />
      )}
    </>
  );
};

export default OrderList;
