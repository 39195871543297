import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#F78B44', // Set the primary color
    },
    secondary: {
      main: '#000000',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: '#F78B44', // Set the background color of the AppBar
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: '#F78B44', // Set the background color of the Toolbar within the AppBar
      },
      dense: {
        backgroundColor: '#F78B44', // Set the background color of the dense Toolbar
      },
    },
  },
});

export default customTheme;
