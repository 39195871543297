import React, { useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  SelectInput,
  Button,
  useRecordContext,
  FunctionField,
} from 'react-admin';
import { get, has } from 'lodash';
import { IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChefExporter from './ChefExporter';
import PencilIcon from '../../icons/PencilIcon';
import CustomModal from '../modal/CustomModal';
import apiWrapper from '../../api/wrapper';
import ExpandColumn from '../components/ExpandColumn';

const postFilters = [
  <TextInput label="Id" source="chefId" />,
  <TextInput label="Email" source="email" />,
  <SelectInput
    label="Status"
    source="status"
    choices={[
      { id: 'active', name: 'Active' },
      { id: 'inActive', name: 'Inactive' },
    ]}
  />,
  <TextInput label="City" source="address.city" />,
  <TextInput label="Cuisine" source="cuisineType" />,
];

const CustomButton = ({ onClick }) => {
  const record = useRecordContext();

  const handleClick = () => {
    if (record) {
      onClick(record);
    } else {
      console.error('Record data is undefined or null.');
    }
  };

  return (
    <Button color="secondary" onClick={handleClick}>
      <PencilIcon />
    </Button>
  );
};

const ChefsList = (props) => {
  const [selectedChef, setSelectedChef] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ctaStatus, setCTAStatus] = useState(true);
  const [error, setError] = useState('');

  const handleStatusClick = (record) => {
    setSelectedChef(record);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const updateChefStatus = async () => {
    setError('');
    setCTAStatus(false);
    const status =
      has(selectedChef, 'status') && selectedChef.status === 'Inactive'
        ? 'active'
        : 'inActive';
    const resp = await apiWrapper(
      `/v1/chef/status/${selectedChef._id}`,
      'PATCH',
      { status }
    );
    setCTAStatus(true);
    if (has(resp, 'statusCode') && resp.statusCode === 200) {
      selectedChef.status = status === 'active' ? 'Active' : 'Inactive';
      setIsModalOpen(false);
    } else {
      setError('An error occurred. Please contact the developer team.');
    }
  };

  const handleButtonClick = async (record) => {
    if (record) {
      const baseUrl = `${process.env.REACT_APP_WEB_APP_URL}/impersonation`;
      const idToken = await localStorage.getItem('token');
      const queryParams = new URLSearchParams({
        page: 'profile',
        chefId: record._id,
        token: idToken,
      });
      const url = `${baseUrl}?${queryParams}`;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      }
    } else {
      console.error('Record data is undefined or null.');
    }
  };

  return (
    <>
      <List {...props} filters={postFilters} exporter={ChefExporter}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="ID" sortable={false} />
          <FunctionField
            label="Status"
            render={(record) => (
              <div
                style={{ minWidth: '90px' }}
                onClick={() => handleStatusClick(record)}
              >
                {record.status}
                <IconButton>
                  <KeyboardArrowDownIcon fontSize="small" />
                </IconButton>
              </div>
            )}
          />
          <CustomButton onClick={handleButtonClick} label="Action" />
          <TextField source="displayName" sortable={false} />
          <TextField source="email" sortable={false} />
          <TextField source="phoneNumber" label="Phone" sortable={false} />
          <TextField source="city" sortable={false} />
          <TextField source="address" label="Full address" sortable={false} />
          <ExpandColumn source="cuisineType" label="Cuisine" sortable={false} />
          <TextField source="minCater" label="Min cater" sortable={false} />
          <TextField source="maxCater" label="Max cater" sortable={false} />
          <TextField
            source="availabilityDay"
            label="Availability"
            sortable={false}
          />
          <TextField source="name" label="Name on card" sortable={false} />
          <TextField source="sortCode" sortable={false} />
          <TextField source="accountNumber" sortable={false} />
        </Datagrid>
      </List>
      {isModalOpen && (
        <CustomModal
          open={isModalOpen}
          title="Change chef status"
          body={
            has(selectedChef, 'status') && selectedChef.status === 'Inactive'
              ? `Ready to welcome Chef ${get(
                  selectedChef,
                  'displayName',
                  ''
                )} to the team? By confirming, we'll activate their account and send a warm welcome email.`
              : `Are you sure you want to temporarily suspend Chef ${get(
                  selectedChef,
                  'displayName',
                  ''
                )}'s account? This will set their status to pending. They won't be notified of this change.`
          }
          submitButtonTxt={
            has(selectedChef, 'status') && selectedChef.status === 'Inactive'
              ? 'Activate chef account'
              : 'Pause chef account'
          }
          cancelButtonTxt="Cancel"
          onAction={updateChefStatus}
          actionButtonStatus={ctaStatus}
          onClose={handleCloseModal}
          chefData={selectedChef}
          error={error}
        />
      )}
    </>
  );
};

export default ChefsList;
