// src/PostList.js
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  Button,
  useRecordContext,
  AutocompleteArrayInput,
} from 'react-admin';
import { isEmpty } from 'lodash';
import MenuExporter from './MenuExporter';
import { dietary, serving } from '../../data/option';
import PencilIcon from '../../icons/PencilIcon';
import ExpandColumn from '../components/ExpandColumn';

const postFilters = [
  <AutocompleteArrayInput
    label="Diatary"
    source="dietary"
    choices={dietary}
    multiple
  />,
  <AutocompleteArrayInput
    label="Serving"
    source="servingStyle"
    choices={serving}
    multiple
  />,
];

const CustomButton = ({ onClick }) => {
  const record = useRecordContext();

  const handleClick = () => {
    if (record) {
      onClick(record);
    } else {
      console.error('Record data is undefined or null.');
    }
  };

  return (
    <Button color="secondary" onClick={handleClick}>
      <PencilIcon />
    </Button>
  );
};

const MenusList = (props) => {
  const handleButtonClick = async (record) => {
    if (record && !isEmpty(record.chefId)) {
      const baseUrl = `${process.env.REACT_APP_WEB_APP_URL}/impersonation`;
      const idToken = await localStorage.getItem('token');
      const queryParams = new URLSearchParams({
        page: 'menu',
        chefId: record.chefId,
        token: idToken,
      });
      const url = `${baseUrl}?${queryParams}`;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      }
    } else {
      console.error('Record data is undefined or null.');
    }
  };

  return (
    <List {...props} filters={postFilters} exporter={MenuExporter}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="chefName" sortable={false} />
        <CustomButton onClick={handleButtonClick} label="Action" />
        <TextField source="menuName" label="Menu Name" sortable={false} />
        <TextField source="dietary" label="Diatary" sortable={false} />
        <TextField source="servingStyle" label="Serving" sortable={false} />
        <TextField source="dishes" label="Dishes" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default MenusList;
